@import '@angular/cdk/overlay-prebuilt.css'

@import '@technokratos/components-styles/reset'
@import '@technokratos/components-styles/components/index'

@import "mixins"
@import "main-styles"
@import "client-typographics"

body
	font-family: Grandis, sans-serif

tk-icon
	fill: currentColor
	display: inline-block
	font-size: 0

	svg
		display: block
		width: 100% !important
		height: 100% !important

.tk-label
	transform: none !important
	font-size: 32px !important
	line-height: 40px !important
	color: white !important
	font-family: GrandisExtended, sans-serif !important

	@include mobile
		font-size: 20px !important
		line-height: 24px !important

.cdk-overlay-pane
	max-width: 100% !important

.cookies-dialog-container
	align-self: flex-end
	padding: 0 12px 16px

	@include medium-mobile
		width: 100%

.with-aside
	display: flex

	@include tablet
		flex-direction: column

	tk-sidebar
		margin-right: 16px

	@include desktop-small
		margin: 0 0 32px 0

body
	&.blue-scroll
		+blue-scrollbar

		&::-webkit-scrollbar
			height: 14px
			width: 6px

*:focus
	outline: none
