@import "mixins"

@font-face
	font-family: Grandis
	src: url('/assets/fonts/grandis_light/Grandis-Light.woff2') format('woff2'), url('/assets/fonts/grandis_light/Grandis-Light.ttf') format('truetype')
	font-weight: 300
	font-style: normal
	font-display: swap

@font-face
	font-family: Grandis
	src: url('/assets/fonts/grandis_regular/Grandis-Regular.woff2') format('woff2'), url('/assets/fonts/grandis_regular/Grandis-Regular.ttf') format('truetype')
	font-weight: normal
	font-style: normal
	font-display: swap

@font-face
	font-family: Grandis
	src: url('/assets/fonts/grandis_medium/Grandis-Medium.woff2') format('woff2'), url('/assets/fonts/grandis_medium/Grandis-Medium.ttf') format('truetype')
	font-weight: 500
	font-style: normal
	font-display: swap

@font-face
	font-family: GrandisExtended
	src: url('/assets/fonts/grandis_extended_regular/GrandisExtended-Regular.woff2') format('woff2'), url('/assets/fonts/grandis_extended_regular/GrandisExtended-Regular.ttf') format('truetype')
	font-weight: normal
	font-style: normal
	font-display: swap

@font-face
	font-family: GrandisExtended
	src: url('/assets/fonts/grandis_extended_medium/GrandisExtended-Medium.woff2') format('woff2'), url('/assets/fonts/grandis_extended_medium/GrandisExtended-Medium.ttf') format('truetype')
	font-weight: 500
	font-style: normal
	font-display: swap

@font-face
	font-family: NeueMachina
	src: url('/assets/fonts/neue_machina_regular/NeueMachina-Regular.woff2') format('woff2'), url('/assets/fonts/neue_machina_regular/NeueMachina-Regular.ttf') format('truetype')
	font-weight: 400
	font-style: normal
	font-display: swap

@font-face
	font-family: NeueMachina
	src: url('/assets/fonts/neue_machina_medium/NeueMachina-Medium.woff2') format('woff2'), url('/assets/fonts/neue_machina_medium/NeueMachina-Medium.ttf') format('truetype')
	font-weight: 500
	font-style: normal
	font-display: swap

@font-face
	font-family: NeueMachina
	src: url('/assets/fonts/neue_machina_bold/NeueMachina-Bold.woff2') format('woff2'), url('/assets/fonts/neue_machina_bold/NeueMachina-Bold.ttf') format('truetype')
	font-weight: 700
	font-style: normal
	font-display: swap

@font-face
	font-family: Gilroy
	src: url('/assets/fonts/gilroy_regular/Gilroy-Regular.woff2') format('woff2'), url('/assets/fonts/gilroy_regular/Gilroy-Regular.ttf') format('truetype')
	font-weight: 400
	font-style: normal
	font-display: swap

@font-face
	font-family: Gilroy
	src: url('/assets/fonts/gilroy_medium/Gilroy-Medium.woff2') format('woff2'), url('/assets/fonts/gilroy_medium/Gilroy-Medium.ttf') format('truetype')
	font-weight: 500
	font-style: normal
	font-display: swap

@font-face
	font-family: Gilroy
	src: url('/assets/fonts/gilroy_bold/Gilroy-Bold.woff2') format('woff2'), url('/assets/fonts/gilroy_bold/Gilroy-Bold.ttf') format('truetype')
	font-weight: 700
	font-style: normal
	font-display: swap
