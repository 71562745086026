@import "mixins"
@import "typographics"

h1
	font-family: GrandisExtended, sans-serif
	font-style: normal
	font-weight: 500
	font-size: 86px
	line-height: 90px

	@include mobile
		font-size: 40px
		line-height: 52px

h2
	font-family: GrandisExtended, sans-serif
	font-style: normal
	font-weight: 400
	@include font-56-60

	@include mobile
		@include font-32-40

h3
	font-family: GrandisExtended, sans-serif
	font-style: normal
	font-weight: 400
	@include font-32-40
 
	@include mobile
		@include font-24-32

h4
	font-family: GrandisExtended, sans-serif
	font-style: normal
	font-weight: 400
	@include font-20-32

	@include mobile
		@include font-20-24
